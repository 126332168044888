/*eslint-disable*/
import React from "react";
import { useEffect, useRef } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import PhotoIcon from '@material-ui/icons/Photo';
import CodeIcon from '@material-ui/icons/Code';
import InfoIcon from '@material-ui/icons/Info';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Button from "components/CustomButtons/Button.js";
import profilePic from "assets/img/faces/christian.jpg";
//import components
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";
import WorkTab from "../mainPageComponents/WorkTab"
import AboutTab from "../mainPageComponents/AboutTab"
import MediaTab from "../mainPageComponents/MediaTab"
//import libaries
import TextLoop from "react-text-loop";
import { init } from 'ityped'

const useStyles = makeStyles(profilePageStyle);

export default function ProfilePage({ ...rest }) {
  const classes = useStyles();
  const textRef = useRef();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );

  function navigate(url) {
    window.open(url, '');
  };

  useEffect(() => {
    init(textRef.current, {
      showCursor: true,
      backDelay: 1500,
      backSpeed: 60,
      strings: ["Developer", "Pet Lover", "Apple Enthusiast"]
    })
  }, [])

  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
      <Header
        color="transparent"
        brand="Kok Hung"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "gray"
        }}
        {...rest}
      />
      <Parallax
        image={require("assets/img/landingBG.jpg")}
        // filter="dark"
        className={classes.parallax}
      >
        <canvas className={classes.canvasTop} id="canvas"></canvas>

        <div className={classes.container}>
          <GridContainer>
            <GridItem className={classes.customZindex}>
              <div className={classes.brand}>
                {window.innerWidth < 768  ? 
                <div>
                  <h2>
                  Hi There! I'm <br></br>
                  </h2>
                  <h1>Kok Hung</h1>
                  <div className={classes.subtitleBadge}>
                    <h2>
                      <span ref={textRef}></span>
                    </h2>
                  </div>
                </div>
                :
                <div className="wrapper">
                  <h2>
                  Hi There! I'm 
                  </h2>
                  <h1>Kok Hung</h1>
                  {/* <span ref={textRef} ></span> */}
                  {/* <TextLoop springConfig={{ stiffness: 70, damping: 31 }}
                    djustingSpeed={500}>
                    <span>Kok Hung</span>
                    <span>a Developer</span>
                    <span>a ReactJS Engineer</span>
                    <span>an Apple Enthusiast</span>
                    <span>a pet lover :)</span>
                </TextLoop>
                  {" "}  */}
                  <br></br>
                  <div className={classes.subtitleBadge}>
                    <span ref={textRef}></span>
                  </div>
                </div>
                }
                
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <div className={classes.profile}>
                <div>
                  <img src={profilePic} alt="..." className={imageClasses} />
                </div>
                <div className={classes.name}>
                  <h3 className={classes.title}>Kok Hung Chan</h3>
                  <h6>Developer</h6>
                  <Button
                    justIcon
                    simple
                    color="instagram"
                    onClick={() => navigate("https://www.instagram.com/khungchan/")}
                    className={classes.margin5}
                  >
                    <i className={classes.socials + " fab fa-instagram"} />
                  </Button>
                  <Button
                    justIcon
                    simple
                    color="twitter"
                    onClick={() => navigate("https://twitter.com/KhchanThatsme")}
                    className={classes.margin5}
                  >
                    <i className={classes.socials + " fab fa-twitter"} />
                  </Button>
                  <Button
                    justIcon
                    simple
                    color="github"
                    onClick={() => navigate("https://github.com/Kokhungchan")}
                    className={classes.margin5}
                  >
                    <i className={classes.socials + " fab fa-github"} />
                  </Button>
                </div>
              </div>
              <div className={classes.follow}>
                <Tooltip
                  id="tooltip-top"
                  title="Connect with me"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
              <Button 
                color="linkedin" 
                className={classes.linkedinButton}
                justIcon
                round
                onClick={() => navigate("https://www.linkedin.com/in/kokhungchan/")}
                >
                <i className="fab fa-linkedin" />
              </Button>
                </Tooltip>
              </div>
            </GridItem>
          </GridContainer>
          <div className={classNames(classes.description, classes.textCenter)}>
            <p>
              Passionate coder, Chan Kok Hung — Graduated University West Of England majoring SE,
              What I do — write codes, read, play, What I love — travel, tech update, pets, photos.
              <br></br>
              Welcome to my playground
              {" "}
            </p>
          </div>
          <div className={classes.profileTabs}>
            <NavPills
              alignCenter
              color="primary"
              tabs={[
                {
                  tabButton: "Work",
                  tabIcon: CodeIcon,
                  tabContent: (
                    <WorkTab/>
                  )
                },
                {
                  tabButton: "About Me",
                  tabIcon: InfoIcon,
                  tabContent: (
                    <AboutTab/>
                  )
                },
                {
                  tabButton: "Media",
                  tabIcon: PhotoIcon,
                  tabContent: (
                    <MediaTab/>
                  )
                }
              ]}
            />
          </div>
          <Clearfix />
        </div>
      </div>
      <Footer
        content={
          <div>
            <div className={classes.left}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://drive.google.com/file/d/18J-G3HfoRInj5DkYBR6efVUn1udFUGDE/view"
                    target="_blank"
                    className={classes.block}
                  >
                    Resume
                  </a>
                </ListItem>
                {/* <ListItem className={classes.inlineBlock}>
                  <a href="https://kokhung.blog/" className={classes.block}>
                    Blog
                  </a>
                </ListItem> */}
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.creative-tim.com/license?ref=mkpr-profile"
                    target="_blank"
                    className={classes.block}
                  >
                    Licenses
                  </a>
                </ListItem>
              </List>
            </div>
            <div className={classes.right}>
              &copy; {1900 + new Date().getYear()} {" "}
              kh
            </div>
          </div>
        }
      />
    </div>
  );
}
