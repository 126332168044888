import React from "react";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import companyLogo1 from "assets/img/logo1.jpg";
import companyLogo2 from "assets/img/logo2.jpg";
import companyLogo3 from "assets/img/logo3.jpg";
import Muted from "components/Typography/Muted.js";
import CardBody from "components/Card/CardBody.js";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";
import Badge from "components/Badge/Badge.js";

const useStyles = makeStyles(profilePageStyle);

export default function AboutTab(props) { 
    const classes = useStyles();

    return(
        <div>             
            <GridContainer justify="center">
 
                <GridItem
                    xs={12}
                    sm={12}
                    md={8}>
                    <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        className={classes.gridItem}
                        >
                            <Card profile plain className={classes.card}>
                                <GridContainer>
                                    <GridItem xs={3} sm={3} md={3} className={classes.cardPic}>
                                        <CardHeader image plain>
                                        <a href="#pablo">
                                            <img src={companyLogo3} alt="Hlb logo" />
                                        </a>
                                        <div
                                            className={classes.coloredShadow}
                                            style={{
                                            backgroundImage: "url(" + companyLogo2 + ")",
                                            opacity: "1"
                                            }}
                                        />
                                        </CardHeader>
                                    </GridItem>
                                    <GridItem xs={9} sm={9} md={9}>
                                        <CardBody plain>
                                        <h4 className={classes.cardTitle}>
                                            Hong Leong Bank Berhad
                                        </h4>
                                        <Muted>
                                            <h6>iOS Developer (2021 - present)</h6>
                                        </Muted>
                                        <ul className={classes.description}>
                                        <li>Implement and maintain using Objective C, Xcode, Interface Builder, Cocoa touch and other development tools</li>
                                        <li>Practiced Agile methodology (daily standup)</li>
                                        <li>Actively fix assigned bugs from JIRA</li>
                                        <li>Worked closely with backend engineers, product managers, RIB and pen-tester to ensure deliverables are delivered on time</li>
                                        <li>Implemented analytics tools such as Adobe tagging</li>
                                        </ul>
                                        </CardBody>
                                    </GridItem>
                                </GridContainer>
                            </Card>
                            <Card profile plain className={classes.card}>
                                <GridContainer>
                                    <GridItem xs={3} sm={3} md={3} className={classes.cardPic}>
                                        <CardHeader image plain>
                                        <a href="#pablo">
                                            <img src={companyLogo2} alt="Zchwantech logo" />
                                        </a>
                                        <div
                                            className={classes.coloredShadow}
                                            style={{
                                            backgroundImage: "url(" + companyLogo2 + ")",
                                            opacity: "1"
                                            }}
                                        />
                                        </CardHeader>
                                    </GridItem>
                                    <GridItem xs={9} sm={9} md={9}>
                                        <CardBody plain>
                                        <h4 className={classes.cardTitle}>
                                            Zchwantech Sdn Bhd
                                        </h4>
                                        <Muted>
                                            <h6>Front-End Developer (2020 - 2021)</h6>
                                        </Muted>
                                        <ul className={classes.description}>
                                        <li>Developed and deployed fully responsive websites using ReactJS</li>
                                        <li>Collaborate and translate designer's mockup into working websites</li>
                                        <li>Enhanced codebase into reusable components and libraries</li>
                                        <li>Experienced in version control with git and various deployment environment stages (local/staging/uat/production)</li>
                                        </ul>
                                        </CardBody>
                                    </GridItem>
                                </GridContainer>
                            </Card>
                        </GridItem>
                    <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    className={classes.gridItem}
                >
                    <Card profile plain className={classes.card} >
                            <GridContainer>
                                <GridItem xs={3} sm={3} md={3} className={classes.cardPic}>
                                    <CardHeader image plain>
                                    <a href="#pablo">
                                        <img src={companyLogo1} alt="Glocomp logo" />
                                    </a>
                                    <div
                                        className={classes.coloredShadow}
                                        style={{
                                        backgroundImage: "url(" + companyLogo1 + ")",
                                        opacity: "1"
                                        }}
                                    />
                                    </CardHeader>
                                </GridItem>
                                <GridItem xs={9} sm={9} md={9}>
                                    <CardBody plain>
                                    <h4 className={classes.cardTitle}>
                                        Glocomp Systems Sdn Bhd
                                    </h4>
                                    <Muted>
                                        <h6>System Engineer Intern (2018)</h6>
                                    </Muted>
                                    <ul className={classes.description}>
                                        <li>Deployment and manage using VMware ESXi hypervisor</li>
                                        <li>Configuring data storage, data management using SQL</li>
                                        <li>Cloud computing using VMSphere</li>
                                        <li>Analyze and troubleshoot operating system problems</li>
                                        <li>Manage and monitor LAN and WAN networks</li>
                                        <li>Study and help to design network topologies</li>
                                    </ul>
                                    </CardBody>
                                </GridItem>
                            </GridContainer>
                        </Card>
                </GridItem>
                </GridItem>
                <GridItem
                xs={12}
                sm={12}
                md={4}
                className={classes.gridItem}>
                    <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        className={classes.gridItem}>
                        <div id="myCanvasContainer">
                            <canvas width="400" height="400" id="myCanvas" className={classes.canvas}>
                                <ul id="weightTags">
                                <li><a>SwiftUI</a></li>
                                <li><a>ReactJS</a></li>
                                <li><a>Html</a></li>
                                <li><a>Css</a></li>
                                <li><a>UIKit</a></li>
                                <li><a>Objective-C</a></li>
                                <li><a>Cocoapods</a></li>
                                <li><a>npm</a></li>
                                <li><a>Git</a></li>
                                <li><a>SQL</a></li>
                                <li><a>Swift</a></li>
                                <li><a>JavaScript</a></li>
                                <li><a>Java</a></li>
                                <li><a>XCode</a></li>
                                <li><a>Bootstrap</a></li>
                                <li><a>Node JS</a></li>
                                <li><a>Storyboard</a></li>
                                <li><a>ES5/ES6</a></li>
                                <li><a>JSON</a></li>
                                </ul>
                            </canvas>
                        </div>
                    </GridItem>
                    <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        className={classes.gridItem}
                        >
                        <h4 className={classes.title}>What I speak</h4>
                        <ul className={classes.listUnstyled}>
                        <li>
                            <b>Front-end</b>
                            <CustomLinearProgress
                            variant="determinate"
                            color="primary"
                            value={85}
                        />
                        </li>
                        <li>
                            <b>ReactJS</b>
                            <CustomLinearProgress
                            variant="determinate"
                            color="progress1"
                            value={80}
                        />
                        </li>
                        <li>
                            <b>Objective-C</b> 
                            <CustomLinearProgress
                            variant="determinate"
                            color="progress2"
                            value={70}
                        />
                        </li>
                        <li>
                            <b>RXSwift</b>
                            <CustomLinearProgress
                            variant="determinate"
                            color="progress3"
                            value={55}
                        />
                        </li>
                        </ul>
                        <hr />
                        <h4 className={classes.title}>About</h4>
                        <p className={classes.description}>
                        Started as front-end developer, explored with ReactJS framework. Currently focusing on front end programming (Mobile/Web)
                        </p>
                        <hr />
                        <h4 className={classes.title}>Focus</h4>
                        <Badge color="primary">Swift</Badge>
                        <Badge color="rose">ReactJS</Badge>
                        <Badge color="info">Mobile</Badge>
                    </GridItem>
                </GridItem>
            </GridContainer>    
        </div>
    );
}