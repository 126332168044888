import photo6 from "assets/img/examples/photo6.jpg";
import photo5 from "assets/img/examples/photo5.jpg";
import photo4 from "assets/img/examples/photo4.jpg";
import photo3 from "assets/img/examples/photo3.jpg";
import photo2 from "assets/img/examples/photo2.jpg";
import photo1 from "assets/img/examples/photo1.jpg";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { makeStyles } from "@material-ui/core/styles";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";
import classNames from "classnames";
import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component'; 

const useStyles = makeStyles(profilePageStyle);

export default function MediaTab (props) {
    const classes = useStyles();
    const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

    return(
        <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
            {/* <img
                alt="..."
                src={photo1}
                className={navImageClasses}
            /> */}
            <LazyLoadImage
                alt="..."
                src={photo2}
                className={navImageClasses}
            />
            <LazyLoadImage
                alt="..."
                src={photo6}
                className={navImageClasses}
            />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
            <LazyLoadImage
                alt="..."
                src={photo3}
                className={navImageClasses}
            />
            <LazyLoadImage
                alt="..."
                src={photo4}
                className={navImageClasses}
            />
            <LazyLoadImage
                alt="..."
                src={photo5}
                className={navImageClasses}
            />
            </GridItem>
        </GridContainer>
    );
}