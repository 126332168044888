/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";

//import library and components
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import project1 from "assets/img/project1.jpeg";
import project2 from "assets/img/project2.jpeg";
import project3 from "assets/img/project3.jpeg";
import project4 from "assets/img/project4.png";
import Badge from "components/Badge/Badge.js";
import { LazyLoadImage } from 'react-lazy-load-image-component'; 
import Button from "components/CustomButtons/Button.js";
import Subject from "@material-ui/icons/Subject";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";


const useStyles = makeStyles(profilePageStyle);

export default function WorkTab(props) {
    const classes = useStyles();

    return(
    <GridContainer>
        <GridItem
        xs={12}
        sm={12}
        md={12}
        className={classes.gridItem}
        >
            <h4 className={classes.title}>Projects</h4>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card
                        className={classes.cardInline}
                    >
                        {window.innerWidth < 834 ? (
                                <LazyLoadImage
                                className={classes.imgCardTop}
                                src= {project4}
                                alt="Card-img-cap"
                            />
                        ):(null)}
                        <CardBody background className={classes.cardBody}>
                  
                            <h4 className={classes.cardTitle}>
                            HLB Connect Mobile App (iOS)
                            </h4>
                            <h5>
                            HLB Connect App is designed to make your day-to-day banking easy with its features that enable you to get more done without having to step foot in a bank’s branch or to call the Contact Center. The app offers language options (English, BM & Chinese) and Day/Night mode for your customisation.
                            </h5>
                      
                        <Badge
                            color="warning"
                            className={classes.badge}
                        >
                            Objective-C
                        </Badge>
                        <a href="https://apps.apple.com/us/app/hlb-connect-mobile-banking-app/id1446719260" className={classes.link}>Apple App Store</a>
                        </CardBody>
                        {window.innerWidth > 834 ? (                      
                        <LazyLoadImage
                            className={classes.imgCardTop}
                            src= {project4}
                            alt="Card-img-cap"
                        />) : (null)}
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Card
                        className={classes.cardInline}
                    >
                        <LazyLoadImage
                            className={classes.imgCardTop}
                            src= {project2}
                            alt="Card-img-cap"
                        />
                        <CardBody background className={classes.cardBody}>
                            <h4 className={classes.cardTitle}>
                            Website
                            </h4>
                            <h5>Previous position as React front end developer. I'm responsible for translating design mockups into working websites. 
                                Frameworks and libraries included MVC, ReactJS, I18next, Redux</h5>
                        <Badge
                            color="warning"
                            className={classes.badge}
                        >
                            ReactJS
                        </Badge>
                        <a href="http://zchwantech.com/" className={classes.link}>Link to website</a>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Card
                        className={classes.cardInline}
                    >
                        {window.innerWidth < 834 ? (
                                <LazyLoadImage
                                className={classes.imgCardTop}
                                src= {project3}
                                alt="Card-img-cap"
                            />
                        ):(null)}
                        <CardBody background className={classes.cardBody}>
                  
                            <h4 className={classes.cardTitle}>
                            Expense App
                            </h4>
                            <h5>A personal experimental project that I wanted to tryout during my freetime, an expense application that keep track
                                of expenses which can be categorised. Created with React-Native and state management library (redux)
                            </h5>
                      
                        <Badge
                            color="warning"
                            className={classes.badge}
                        >
                            React-Native
                        </Badge>
                        <a href="https://github.com/Kokhungchan/ExpenseApp" className={classes.link}>Github: Expense App</a>
                        </CardBody>
                        {window.innerWidth > 834 ? (                      
                        <LazyLoadImage
                            className={classes.imgCardTop}
                            src= {project3}
                            alt="Card-img-cap"
                        />) : (null)}
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Card
                        className={classes.cardInline}
                    >
                        <LazyLoadImage
                            className={classes.imgCardTop}
                            src= {project1}
                            alt="Card-img-cap"
                        />
                        <CardBody background className={classes.cardBody}>
                            <h4 className={classes.cardTitle}>
                            Website
                            </h4>
                            <h5>My first version personal website coded in pure Html and Css. Hosted using Github Pages, 
                                it is a simple and static website showcasing my resume, work experiences and my achievements. </h5>
                        <Badge
                            className={classes.badge}
                        >
                            Html & Css
                        </Badge>
                        <a href="https://github.com/Kokhungchan/kokhungchan.github.io" className={classes.link}>Github: Github.io</a>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </GridItem>
    </GridContainer>
    );
}